import React from "react"

import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../components/seo"

import Button from "../components/button"
import ButtonP from "../components/button-prueba"
import MainBanner from "../components/main-banner"
import ConceptSection from "../components/concept-section"

import RocketIcon from "../assets/icons/icons-1x/blue-rocket@2x.png"
import IdeaIcon from "../assets/icons/icons-1x/idea.svg"
import ClockIcon from "../assets/icons/icons-1x/clock.svg"
import EspiralMeiquerLogo from "../assets/logos/logo-espiral-meiquer.svg"

const EspiralMeiquerPage = props => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      scratchImg: file(relativePath: { eq: "meiquer-jocotan-04-scratch.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cursoEscuelaImg: file(
        relativePath: { eq: "meiquer-curso-escuela-01.jpg" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const mainBannerContent = (
    <>
      <img
        style={{ width: "7em" }}
        src={EspiralMeiquerLogo}
        alt="Espiral Meiquer Logo"
      />
      <p>
        Lleva la experiencia{" "}
        <span className="c-turquoise w-bold">Meiquer</span>a tu{" "}
        <span className="w-bold">hogar</span>
      </p>
      <p>
        Actividades virtuales de{" "}
        <span className="c-turquoise w-bold">juego creativo</span> {" "}
        para niños y niñas que le ayudarán a{" "}
        <span className="w-bold">imaginar</span> en papel,{" "}
        <span className="w-bold">crear</span> con las manos y{" "}
        <span className="w-bold">compartir</span> en comunidad.
      </p>
      <div className="columns">
        <div className="column is-two-thirds pl-0">
          <Button href="https://mailchi.mp/f7394ab42d95/espiral-meiquer-registro">
            <span>Suscribirme</span>
          </Button>
        </div>
      </div>
    </>
  )

  const infoBannerContent = (
    <>
      <h2 className="w-normal">
        <span className="c-turquoise w-bold">Juega</span> y{" "}
        <span className="w-bold">sueña</span> con{" "}
        <span className="s-italic">historias</span>
      </h2>
      <p>
        Nuestras  <span className="w-bold">misiones </span>son actividades que cuentan{" "}
        <span className="w-bold">historias</span> que inspiran a las niñas y niños a jugar,
        explorar, imaginar y aprender análoga y digitalmente.
      </p>
      <div className="columns">
        <div className="column is-two-thirds pl-0">
          <Button href="https://www.youtube.com/watch?v=Rvjdp6k2EVQ">
            <span>Ver Ejemplo</span>
          </Button>
        </div>
      </div>
    </>
  )

  const reasons = [
    {
      top: (
        <img style={{ height: "10rem" }} src={ClockIcon} alt="Ícono Reloj" />
      ),
      content: (
        <>
          <h3>Ahorra Tiempo</h3>
          <p>
            Recibes el contenido por WhatsApp, explicas 10 minutos y ¡listo!
            Hasta 1 hora de juego sin interrupción.
          </p>
        </>
      ),
    },
    {
      top: <img style={{ height: "10rem" }} src={IdeaIcon} alt="Ícono Idea" />,
      content: (
        <>
          <h3>Adáptalo a tu Rutina</h3>
          <p>
            Las actividades son de 15 minutos o 1 hora, para jugar en familia o
            solo, con materiales que ya tienes.
          </p>
        </>
      ),
    },
    {
      top: (
        <img style={{ height: "10rem" }} src={RocketIcon} alt="Ícono Cohete" />
      ),
      content: (
        <>
          <h3>Aprende y Crece</h3>
          <p>
            Retos para jugar y desarrollar habilidades con Ciencia, Tecnología,
            Matemáticas, Ingeniería y Arte.
          </p>
        </>
      ),
    },
  ]

  const reasonsTitle = (
    <h2 className="w-normal text-right">
      ¿<span className="s-italic">Cómo se</span>{" "}
      <span className="c-turquoise w-bold">integra</span>{" "}
      <span className="s-italic">a tu</span> <span className="w-bold">día</span>
      ?
    </h2>
  )

  const services = [
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={EspiralMeiquerLogo}
            alt="Espiral Meiquer Logo"
          />
          <p style={{ letterSpacing: " .3rem" }} className="c-turquoise w-bold">
            ¿Nuevo?
          </p>
        </>
      ),
      content: (
        <>
          <p>
            Si es la primera vez que juegas con nosotros, obtén la primera semana gratis
          </p>

        </>
      ),
      bottom: (
        <ButtonP href="https://mailchi.mp/f7394ab42d95/espiral-meiquer-registro" >
          Prueba Gratis
        </ButtonP>
      ),
    },
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={EspiralMeiquerLogo}
            alt="Espiral Meiquer Logo"
          />
          <p style={{ letterSpacing: " .3rem" }} className="c-turquoise w-bold">
            Básico
          </p>
        </>
      ),
      content: (
        <>
          <p>
            1 Actividad a la semana <span className="w-bold">+</span> Asesoría
            en línea
          </p>
          <p className="c-turquoise">
            <span className="w-bold">$75</span> por semana
          </p>
        </>
      ),
      bottom: (
        <Button href="https://mailchi.mp/f7394ab42d95/espiral-meiquer-registro">
          Suscribirme
        </Button>
      ),
    },
    {
      top: (
        <>
          <img
            style={{ width: "6em" }}
            src={EspiralMeiquerLogo}
            alt="Espiral Meiquer Logo"
          />
          <p style={{ letterSpacing: ".3rem" }} className="c-turquoise w-bold">
            Elemental
          </p>
        </>
      ),
      content: (
        <>
          <p>
            4 Actividades al mes <span className="w-bold">+</span> Asesoría en
            línea
          </p>
          <p className="c-turquoise">
            <span className="w-bold">$250</span> por mes
          </p>
        </>
      ),
      bottom: (
        <Button href="https://mailchi.mp/f7394ab42d95/espiral-meiquer-registro">
          Promoción
        </Button>
      ),
    },
    {},
    {},
  ]

  const servicesTitle = (
    <h2 className="w-normal">
      ¿Quieres <span className="c-turquoise w-bold">jugar</span>{" "}
      <span className="s-italic">y convivir en</span>{" "}
      <span className="w-bold">familia</span>?
    </h2>
  )

  return (
    <Layout location={props.location}>
      <SEO title="Espiral Meiquer" />
      <MainBanner
        image={data.scratchImg.childImageSharp.fluid}
        content={mainBannerContent}
      />
      <ConceptSection concepts={reasons} title={reasonsTitle} />
      <MainBanner
        image={data.cursoEscuelaImg.childImageSharp.fluid}
        content={infoBannerContent}
        fromBottom={false}
      />
      <ConceptSection concepts={services} title={servicesTitle} />
    </Layout>
  )
}

export default EspiralMeiquerPage
